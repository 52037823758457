import { Province } from '@/utils/interface';
import { Cascader, Toast } from 'antd-mobile';
import React, { CSSProperties, useEffect, useState } from 'react'
import sty from './index.module.scss'
interface CityPickerProps {
  onClose?: () => void;
  value?: string[];
  data: Province[];
  visible: boolean;
  defaultValue?: string;
  onChange?: (v: any) => void;
  style?: CSSProperties;
  wrapperStyle?: CSSProperties,
  showChildren?: boolean;
}


export default function CityPicker(props: CityPickerProps) {

  const { data, visible, onClose, onChange, value: defaultValue, showChildren = true } = props
  const [value, setValue] = useState<string[]>(defaultValue ?? [])
  const [options, setOptions] = useState<any[]>([])

  const transformData = () => {
    const _options: any[] = props.data.map(p => {
      p.label = p.provinceName;
      p.value = p.provinceName
      p.children = p.citys.map(c => {
        c.label = c.cityName;
        c.value = c.cityName
        return c;
      })
      return p;
    })
    setOptions(_options);
  }

  useEffect(() => {
    props.data && transformData()
  }, [props])


  return (
    <Cascader
      options={options}
      visible={visible}
      onClose={onClose}
      value={value}
      onConfirm={val => {
        if (val.length < 2) return Toast.show('请选择城市')
        setValue(val)
        onChange && onChange(val)
      }}
      style={props.wrapperStyle}
      className={sty.cityPicker}
    >
      {showChildren ? (items => {
        if (items.every(item => item === null)) {
          return <div style={props.style}>未选择</div>
        } else {
          return <div style={props.style}>{items.slice(-1).map(item => item?.label ?? '未选择')}</div>
        }
      }) : () => null}
    </Cascader>
  )
}
