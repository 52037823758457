import {
  ProductInfo,
  ProtocolsModel,
  RecommendProduct,
} from '@/utils/interface';
import { Popup } from 'antd-mobile';
import React, { useEffect, useMemo, useState } from 'react';
import { HtmlReader } from '..';
import sty from './index.module.scss';
interface ProtocolsModolProps {
  visible: boolean;
  info: ProtocolsModel | undefined;
  parentInfo?: ProductInfo;
  onClose?: () => void;
  fixedHeader?: boolean;
}

const blackList = ['ysbhzc.html'];

export default function useProtocolModal(props: ProtocolsModolProps) {
  const { onClose, info, visible, parentInfo, fixedHeader = false } = props;
  const [variables, setVariables] = useState<Record<string, string>>({});
  const isDefault = true/*  useMemo(
    /// 同域名下且不在黑名单里的使用网页阅读器加载
    () => 
      info &&
      info.protocolContext.startsWith(window.location.origin) &&
      blackList.every((item) => !info.protocolContext.endsWith(item)),
    [info]
  ); */

  useEffect(() => {
    if (info && info.contextParams && parentInfo) {
      //// key=val&key1=val1...
      info.contextParams.split('&').forEach((ctx: string) => {
        const [key, value] = ctx.split('=');
        variables[value] = (parentInfo as any)[key] ?? '';
      });
    }
    setVariables({ ...variables });
  }, [info, parentInfo]);

  return info ? (
    <Popup
      visible={visible}
      showCloseButton
      onClose={onClose}
      onMaskClick={onClose}
      bodyStyle={{
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        height: '70vh',
      }}
    >
      {
        fixedHeader && <div className={sty.header}>{info.protocolName}</div>
      }
      {isDefault ? (
        <div className={sty.body}>
          <HtmlReader uri={info.protocolContext} {...variables} />
        </div>
      ) : (
        <iframe
          className={sty.body}
          title={info.protocolContext}
          src={info.protocolContext + `?timestamp=${+new Date()}`}
          frameBorder='0'
        />
      )}
    </Popup>
  ) : null;
}
