import React, { CSSProperties, useState } from 'react';
import sty from './index.module.scss';
interface CheckboxWidgetProps {
  value?: boolean;
  defaultValue?: boolean;
  onChecked?: (v: boolean) => any;
  activeIcon?: string;
  inactiveIcon?: string;
  size?: string;
  showTips?: boolean;
  style?: CSSProperties
}
export default function useCheckboxWidget(props: CheckboxWidgetProps) {
  const {
    value: derivedValue,
    defaultValue = false,
    onChecked,
    activeIcon,
    inactiveIcon,
    size = 'normal',
    showTips = false,
    style = {}
  } = props;

  const [value, setValue] = useState(derivedValue ?? defaultValue);

  const handleChecked = () => {
    const _value = !value;
    setValue(_value);
    onChecked && onChecked(_value);
  };

  return (
    <div
      className={`${sty.checkbox} ${size === 'lg' ? sty.is_large : ''}`}
      onClick={handleChecked}
      style={style}
    >
      {!value ? (
        <img
          src={inactiveIcon || require('@/assets/imgs/icon-checkbox@2x.png')}
          alt=''
        />
      ) : (
        <img
          src={activeIcon || require('@/assets/imgs/checked@2x.png')}
          alt=''
        />
      )}
    </div>
  );
}
