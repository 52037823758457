import React from "react";
import sty from "./index.module.scss";
import { ProductInfo } from "@/utils/interface";

interface ProductCardProps {
  company?: {
    logo: string;
    name: string;
  };
  amount?: string;
  rate?: string;
  showDownloadBtn?: boolean;
  downloadUrl?: string;
  helpTxt?: string;
  limit?: string;
  title?: string;
  matched?: boolean;
  empty?: boolean;
  matchLimit?: string;
  info?: ProductInfo;
}

export default function useProductCard(props: ProductCardProps) {
  const {
    company: { logo, name } = {},
    amount,
    helpTxt,
    rate,
    showDownloadBtn,
    limit,
    title,
    matched,
    empty,
    info,
    downloadUrl
  } = props;
  return (
    <div
      className={sty.product_main}
      style={empty ? { paddingBottom: ".32rem" } : {}}
    >
      {empty ? (
        <div className={sty.empty}>
          <img src={require("@/assets/imgs/icon-full@2x.png")} alt="" />
          <h1>今日产品额度已满</h1>
        </div>
      ) : (
        <>
          <div className={sty.head}>
            {title}
            <img src={logo} alt="" />
            {name}
          </div>
          <p className={sty.label}>最高可贷（元）</p>
          <div className={sty.amount_wrapper}>
            <div className={`${sty.amount} font-num`}>{amount}</div>
          </div>
          <p className={sty.rate}>参考年化利率{rate}起</p>
        </>
      )}
      {empty && helpTxt && <p className={sty.help}>{helpTxt}</p>}
      {showDownloadBtn && (
        <div className={sty.btn_wrapper}>
          <button
            className={sty.btn}
            onClick={() => {
              window.location.href = downloadUrl || "";
            }}
          >
            下载app获取额度
          </button>
        </div>
      )}
      {!empty && helpTxt && <p className={sty.help}>{helpTxt}</p>}
      {limit && (
        <span className={sty.today_count}>{limit}</span>
      )}
      {
        /// matchLimit
        matched && (
          <img
            src={require("@/assets/imgs/label-match@2x.png")}
            className={sty.matched}
            alt=""
          />
        )
      }
    </div>
  );
}
