import { COUNTDOWN_TIME, REG_PHONE } from '@/utils/constant';
import request, { EHttpMethods, IResponseData } from '@/utils/request';
import { Toast } from 'antd-mobile';
import React, {
  useState,
  useEffect,
  PropsWithChildren,
  forwardRef,
  useImperativeHandle,
} from 'react';

let timeChange: any = null;

interface CountdownBtnProps {
  btnClassName: string;
  phone: string;
  api?: string;
  [propName: string]: any;
}

const CountdownBtn = (
  props: PropsWithChildren<CountdownBtnProps>,
  ref: any
) => {
  const [time, setTime] = useState(COUNTDOWN_TIME);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnContent, setBtnContent] = useState(props.text || '获取验证码');

  useEffect(() => {
    clearInterval(timeChange);
    return () => clearInterval(timeChange);
  }, []);

  useEffect(() => {
    if (time > 0 && time < COUNTDOWN_TIME) {
      setBtnContent(`${props.resendText || '重新获取验证码'}（${time}s）`);
    } else {
      clearInterval(timeChange);
      setBtnDisabled(false);
      setTime(COUNTDOWN_TIME);
      setBtnContent(props.text || '获取验证码');
    }
  }, [time]);

  const getPhoneCaptcha = () => {
    // 注意，不要使用 setTime(t-1) ： 闭包问题会导致time一直为59
    timeChange = setInterval(() => setTime((t) => --t), 1000);
    setBtnDisabled(true);
  };

  const sendMsg = () => {
    if (!REG_PHONE.test(props.phone)) {
      return Toast.show('请输入正确的手机号')
    }
    request<IResponseData>(props.api || '/smart-api/common/smsCode', {
      method: EHttpMethods.POST,
      data: {
        mobile: props.phone,
      },
    }).then(res => {
      if (res.code === 200) {
        Toast.show('已发送');
        getPhoneCaptcha()
      }
    });
  };

  useImperativeHandle(ref, () => ({
    sendMsg,
  }));

  return (
    <button
      disabled={btnDisabled}
      className={props.btnClassName}
      onClick={sendMsg}
      type='button'
    >
      {btnContent}
    </button>
  );
};

export default forwardRef(CountdownBtn);
