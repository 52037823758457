import React, { PropsWithChildren } from 'react'
import sty from './index.module.scss'
import { ButtonProps } from 'antd-mobile'
export default function PrimaryBtn(props: PropsWithChildren<ButtonProps>) {
  return (
    <button {...props} className={`${sty.btn} ${props.className}`}>
        {props.children}
    </button>
  )
}
