import { noop } from '@/utils';
import React, { PropsWithChildren } from 'react'
interface FormItemProps {
  label?: string;
  onClick?: () => void;
  arrow?: React.ReactNode | undefined;
  labelRender?: React.ReactNode;
  footerRender?: React.ReactNode;
}

export default function index(props: FormItemProps & PropsWithChildren) {
  const { label, children, onClick, arrow, labelRender, footerRender } = props
  return (
    <div className="adm-list-item adm-form-item adm-form-item-horizontal" onClick={onClick}>
      {
        !!labelRender ? labelRender : <div className="adm-list-item-content">
          <div className="adm-list-item-content-prefix">
            <label className="adm-form-item-label">
              {label}
            </label>
          </div>
          <div className="adm-list-item-content-main">
            <div className="adm-form-item-child adm-form-item-child-position-normal">
              <div className="adm-form-item-child-inner">
                <div className="adm-input">
                  {children}
                </div>
              </div>
            </div>
            <div className="adm-list-item-description">
            </div>
          </div>
          {
            arrow && (
              <div className="adm-list-item-content-arrow">
                {arrow}
              </div>
            )
          }
        </div>
      }
      {footerRender}
    </div>
  )
}
