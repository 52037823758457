import useFetchData from '@/hooks/useFetchData';
import { fillUri, getBody, replaceByVar, shadowPhone } from '@/utils';
import { LOCAL_MOBILE_KEY, LOCAL_NAME_KEY, REG_URL } from '@/utils/constant';
import { HtmlReaderProps } from '@/utils/interface';
import React from 'react'


export default function useHtmlReader(props: HtmlReaderProps & { uri: string }) {
  const { uri } = props


  const { data, loading } = useFetchData(fillUri(uri), {
    isTxt: true
  });

  return (
    loading ? null : <div dangerouslySetInnerHTML={{
      __html: replaceByVar(getBody(data), {
        ...props,
        /// 姓名
        localName: window.localStorage.getItem(LOCAL_NAME_KEY) ?? '--',
        /// 手机号
        localPhone: shadowPhone(window.localStorage.getItem(LOCAL_MOBILE_KEY)) ?? '--'
      })
    }} />
  )
}
