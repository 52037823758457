export const REG_BODY = /<body[^>]*>([\s\S]*)<\/body>/;
/// 不支持虚拟号段
export const REG_PHONE = /^1\d{10}$/;
export const REG_URL =
	/^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/;

export const COUNTDOWN_TIME = 60;

export const LOCAL_MOBILE_KEY = 'local_mobile_key';

export const LOCAL_NAME_KEY = 'local_name_key';

export const LOCAL_FORM_DATA = 'local_form_data';

export const LOCAL_RECOMMEND_INFO = 'local_recommend_info';

export const LOCAL_SUBMITD_RESULT = 'local_submitedd_result';
