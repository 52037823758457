import React from 'react'
import sty from './index.module.scss'

interface StepItemProps {
  icon: string;
  label: string;
}

interface StepsProps {
  showLine?: boolean;
  list: StepItemProps[];
  title: string;
  bodyClassName?: string
}

export default function index(props: StepsProps) {

  const { title, list = [], showLine, bodyClassName } = props

  return (
    <div className={sty.steps}>
      <div className={sty.title}>{title}</div>
      <div className={`${sty.body} ${!showLine ? sty.hide_line : ''} ${bodyClassName || ''}`}>
        {
          list.map((item, i) => (
            <div key={`step_${i}`} className={sty.item}>
              <img src={item.icon} alt={item.label} />
              {item.label}
            </div>
          ))
        }
      </div>
    </div>
  )
}
