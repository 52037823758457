export default function init() {
	// 自适应布局
	const docEl = document.documentElement;
	const resizeEvt =
		'onorientationchange' in window ? 'onorientationchange' : 'resize';
	const recalc = function () {
		const clientWidth = docEl.clientWidth || window.innerWidth;
		if (!clientWidth || clientWidth >= 1024) {
			docEl.style.fontSize = '37.5px';
		} else {
			docEl.style.fontSize = clientWidth / 10 + 'px';
		}
	};
	if (!document.addEventListener) return;
	window.addEventListener(resizeEvt, recalc, false);
	document.addEventListener('DOMContentLoaded', recalc, false);
}
