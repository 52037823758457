import React from 'react'
import { SpinLoading } from 'antd-mobile'
export default function index() {
  return (
    <div style={{
      display: 'flex',
      width: '100vw',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <SpinLoading color='#0C5FFE' style={{ '--size': '20px' }} />
    </div>
  )
}
