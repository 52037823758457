import { useNavigate } from 'react-router-dom';
import { REG_BODY, REG_PHONE, REG_URL } from './constant';
import { HtmlReaderProps } from './interface';

/** 空函数 */
export function noop() {}

/**
 * 获取html中的body
 * @param {string} content
 * @returns {string}
 */
export const getBody = (content: string) => {
	const result = REG_BODY.exec(content);
	if (result && result.length === 2) return result[1];
	return content;
};

/**
 * 变量替换
 * @param {string} str
 * @param {HtmlReaderProps} props
 * @returns {string}
 */
export const replaceByVar = (
	str: string,
	props: HtmlReaderProps,
	withTag?: string
) => {
	const _props = { ...props };
	delete _props.uri;
	if (!Object.keys(_props).length || !str) return str;
	for (let key in _props) {
		let param = _props[key];
		if (withTag) {
			param = `<${withTag}>${param}</${withTag}>`;
		}
		const _reg = new RegExp(`{${key}}`, 'gi');
		str = str.replace(_reg, param);
	}
	return str;
};

/**
 * 跳转
 */
export const getUrlParams = (name: string) => {
	var result: any = {}; // 定义一个全局的对象
	var str = window.location.search;
	if (str.startsWith('?')) {
		// 判断str以？开头的
		var strParams = str.split('?')[1];
		var arrParams = strParams.split('&');
		//然后进行for循环，这里直接用了forEach
		arrParams.forEach((item) => {
			var temKey = item.split('=')[0];
			var temVal = item.split('=')[1];
			result[temKey] = temVal;
		});
	}
	return result[name];
};

/**
 * 手机号脱敏
 */
export const shadowPhone = (phone: string | null) =>
	phone ? phone.replace(/^(1[3-9][0-9])\d{4}(\d{4}$)/, '$1****$2') : phone;

/** 网址补全 */

export const fillUri = (u: string) =>
	REG_URL.test(u) ? u : window.location.origin + u;
