import { replaceByVar } from '@/utils';
import { CSelectorOptionsProps, ItemList } from '@/utils/interface';
import request, { EHttpMethods } from '@/utils/request';
/// 计算选项数量
const caculateIndex = (arr: ItemList): number => {
	let idx = 0;
	try {
		// 选项子类
		const optionChildren = arr.children?.filter(
			(item) => !item.children?.length
		);
		// 表单子类
		const formChildren = arr.children?.filter(
			(item) => !!item.children?.length
		);
		/// 表示同时存在 子表单 和 “无”
		if (!!optionChildren?.length && !!formChildren?.length) {
			formChildren.map((item) => (idx += item.children?.length ?? 0));
		}
	} catch (error) {}
	return idx;
};
/// 树节点展开
const flattenList = (arr: ItemList[], flag?: boolean): any[] => {
	const res: ItemList[] = arr.reduce((pre: any[], cur: ItemList) => {
		if (flag && cur.isForm) {
			cur.visibleOn =
				'return Object.values(allValues).flat().some(item => item == node.parentLabelCode || item == node.labelCode)';
		}
		/// 默认全部展开 // 除有默认值的都是展开
		cur.expand = true;
		///	cur.hasRecommendText || cur.options.every((item: any) => !item.isDefault);
		if (cur.isForm) {
			pre.push(cur);
		}
		if (cur.children && !!cur.children.length) {
			const m = flattenList(cur.children, true);
			!!m.length && pre.push(...m);
		}
		return pre;
	}, []);
	return res;
};
/// 格式化编辑项
const formatItem = (arr: ItemList[]): any[] => {
	const res: any[] = arr.reduce((pre: any[], cur: ItemList) => {
		/// 格式化鼓励语
		if (typeof cur.encourageParam == 'string' && cur.encourageWord) {
			cur.encourageParam = { p: cur.encourageParam ?? '' } as any;
			cur.encourageWord = replaceByVar(
				cur.encourageWord ?? '',
				cur.encourageParam as any,
				'span'
			);
		}

		if (cur.children && !!cur.children.length) {
			cur.children = formatItem(cur.children);
			/// 区分表单项
			cur.isForm = cur.checkShow === 1;
		} else {
			/// 区分选项
			cur.isOption = true;
		}
		// 生成选项
		cur.options =
			cur.checkShow === 1
				? cur.children?.map((item) => ({
						label: item.labelName,
						value: item.labelCode,
						// 是否默认
						isDefault: item.defaultChoose === 1,
						// 是否显示推荐语
						recommendText: item.checkRecommend === 1 && item.recommendText,
				  }))
				: [];
		cur.hasRecommendText =
			cur.checkShow === 1 &&
			cur.children?.some(
				(item) => item.checkRecommend === 1 && item.recommendText
			);
		cur.defaultValue =
			(cur.options.find((item: any) => item.isDefault) ?? {}).value ??
			undefined;
		pre.push(cur);
		return pre;
	}, []);
	return res;
};
/// 查找更新的表单项下标
const findChangeKeyByValue = (
	value: { [propName: string]: any },
	arr: ItemList[]
): number => {
	const labelCode = Object.entries(value).flat()[0];
	const index = arr.findIndex((item) => item.labelCode === labelCode);
	return index < 0 ? 0 : index;
};

/// 根据当前所有选项，找出下一个该显示的下标
const findNextIndexByValues = (
	changeValue: { [propName: string]: any },
	allValues: { [propName: string]: any },
	arr: ItemList[]
): number => {
	let index = findChangeKeyByValue(changeValue, arr);
	for (let i = index; i < arr.length; i++) {
		const item = arr[i] as ItemList;
		const key = item.labelCode;
		const value = allValues[key] ?? null;
		/// 表单中存在 此 item 查找下一个，
		if (allValues[key]) {
			///
			/// 嵌套的 需要先判断其 parentLabelCode 是否为某表单项的value
			/**
			 * 普通的 直接 index++;
			 * case1: 判断其 parentLabelCode 是否为某表单项的value
			 * yes: index = 其下标
			 * case2: 判断其是否满足显示条件
			 */
			/// 查询子表单，如果有 则重置index为当前子表单的下标
			const childFormIdx = arr.findIndex((n) => n.parentLabelCode === value);
			if (childFormIdx > -1) {
				index = childFormIdx;
			} else {
				index++;
			}
		}
		/// 防止下标溢出
		if (index >= arr.length) index = arr.length - 1;
		/// 如果下一个不能展示 或者 有默认值且未设置value（表示是初始状态） 则 跳过 继续循环
		const _nextItem = arr[index];
		const func = _nextItem.visibleOn
			? // eslint-disable-next-line no-new-func
			  new Function('allValues', 'node', _nextItem.visibleOn)
			: null;
		if (
			(func && !func(allValues, _nextItem)) ||
			(_nextItem.defaultValue && !value)
		) {
			index++;
		}
		continue;
	}
	return index;
};
/// 查找唯一子节点
const findNodeByValue = (
	value: string,
	arr: ItemList[]
): ItemList | undefined => {
	for (let item of arr) {
		if (
			item.labelCode === value &&
			(!item.children || !item.children?.length)
		) {
			return item;
		}
		if (item.children && item.children.length) {
			const node = findNodeByValue(value, item.children);
			if (node) {
				return node;
			}
		}
	}
	return undefined;
};
/// 产品更新
const updatePoint = (node: CSelectorOptionsProps, arr: ItemList[]) => {
	const item = findNodeByValue(node.value, arr);
	if (item) {
		request('/smart-api/pd/item', {
			method: EHttpMethods.POST,
			data: {
				parentLabelCode: item.parentLabelCode,
				labelCode: item.labelCode,
			},
		});
	}
};

export { flattenList, formatItem, findNextIndexByValues, updatePoint };
