import React, { useImperativeHandle, forwardRef, useState } from 'react';
import sty from './index.module.scss';
import { Modal, Popup } from 'antd-mobile';
import { CheckboxWidget, ProtocolModal } from '..';
import { Action } from 'antd-mobile/es/components/action-sheet';
import { ProtocolsModel } from '@/utils/interface';
import request, { EHttpMethods } from '@/utils/request';

interface ProtocolProps {
  onChange?: (v: boolean) => any;
  value?: boolean;
  title: string;
  subtitle: string;
  agreements: ProtocolsModel[] | undefined;
  showTips?: boolean;
  wrapperStyle?: React.CSSProperties;
  className?: string;
  requestAssign?: boolean;
  assignParams?: {
    url?: string;
    [propName: string]: any;
  };
  checkboxNoRequest?: boolean;
  parentInfo?: any;
  fixedHeader?: boolean
}

function useProtocoal(props: ProtocolProps, ref: any) {
  const {
    onChange,
    title,
    subtitle,
    agreements = [],
    value: defaultValue,
    showTips = false,
    wrapperStyle,
    className,
    requestAssign = true,
    assignParams,
    checkboxNoRequest,
    parentInfo,
    fixedHeader
  } = props;

  const [value, setValue] = useState(defaultValue ?? false);
  const [update, setUpdate] = useState(0);
  const [showProtocol, setShowProtocol] = useState<
    ProtocolsModel | undefined
  >();

  const showModal = (cb: () => void) =>
    Modal.alert({
      title,
      showCloseButton: true,
      content: (
        <>
          <div>{subtitle}</div>
          {agreements.map((item, i) => (
            <p key={`modal_agree_${i}`}>
              <a
                href='#'
                onClick={() => setShowProtocol(item)}
              >
                《{item.protocolName}》
              </a>
            </p>
          ))}
        </>
      ),
      onConfirm: () => handleAssign(true, cb, true),
      confirmText: '同意并继续',
    });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useImperativeHandle(ref, () => ({
    showModal,
    value,
    handleAssign,
  }));

  const handleAssign = (
    v: boolean,
    cb?: (v?: any) => void,
    isModal?: boolean
  ) => {
    setValue(v);
    setUpdate(update + 1);
    /// 点图标不需要签署 且 非弹框
    if (checkboxNoRequest && !isModal) return;
    if (!requestAssign) {
      cb && cb();
      return;
    }
    if (v) {
      const _assignParams = { ...(assignParams ?? {}) };
      delete _assignParams.url;
      /// 签署
      request(assignParams?.url ?? '/smart-api/sign/protocol', {
        method: EHttpMethods.POST,
        data: {
          protocolCodeList: agreements.map((item) => item.protocolCode),
          ..._assignParams,
        },
      }).then((res) => {
        cb && cb(res);
        onChange && onChange(v);
      });
    }
  };

  return (
    <div className={`${sty.protocol} ${className || ''}`} style={wrapperStyle}>
      {showTips && !value && <div className={sty.tips}>请勾选协议</div>}
      <CheckboxWidget
        key={`checkbox_${update}`}
        value={value}
        inactiveIcon={require('@/assets/imgs/checkbox@2x.png')}
        defaultValue={value}
        onChecked={handleAssign}
        style={{
          flexShrink: 0
        }}
      />
      <p>
        我已阅读并同意
        {agreements.map((item, i) => (
          <a
            key={`agree_${i}`}
            href='#'
            onClick={() => setShowProtocol(item)}
          >
            《{item.protocolName || '--'}》
          </a>
        ))}
      </p>
      <ProtocolModal
        visible={!!showProtocol}
        info={showProtocol}
        parentInfo={parentInfo}
        fixedHeader={fixedHeader}
        onClose={() => {
          setShowProtocol(undefined);
        }}
      />
    </div>
  );
}
export default forwardRef(useProtocoal);
