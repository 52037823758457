import { ExtraFormProps, ItemList } from '@/utils/interface';
import { Form, Selector } from 'antd-mobile';
import React, { useEffect, useState } from 'react';
import {
  flattenList,
  formatItem,
  findNextIndexByValues,
  updatePoint,
} from './format';
import { CSelector } from '@/components';
import sty from './index.module.scss';

export default function useExtraForm(props: ExtraFormProps) {
  const { onChange, formInfo, initialValues } = props;

  const [form] = Form.useForm();
  const [update, setUpdate] = useState(0);
  const [idx, setIdx] = useState(0);
  const [itemList, setItemList] = useState<ItemList[]>([]);

  useEffect(() => {
    const res = flattenList(formatItem(formInfo.itemList as ItemList[]));
    console.log(res);
    setItemList(res);
  }, []);

  useEffect(() => {
    onChange && onChange(form.getFieldsValue());
  }, [update])

  const handleValuesChange = (changeValue: any, allValues: any) => {
    const nextId = findNextIndexByValues(changeValue, allValues, itemList);
    setIdx(nextId);
    setUpdate(update + 1);
  };


  const PerItem = (props: { formItem: ItemList; index: number }) => {
    const { formItem, index } = props;
    const options = formItem.options ?? [];
    const func = formItem.visibleOn
      ? // eslint-disable-next-line no-new-func
      new Function('allValues', 'node', formItem.visibleOn)
      : null;
    if (index > idx || (func && !func(form.getFieldsValue(), formItem)))
      return null;
    return (
      <>
        {formItem.encourageWord && (
          <div
            className={sty.encourage}
            dangerouslySetInnerHTML={{ __html: formItem.encourageWord }}
          />
        )}
        <Form.Item noStyle={true} name={formItem.labelCode}>
          <CSelector
            label={formItem.labelName}
            arrow={
              <img
                className={`${sty.arrow} ${formItem.expand ? sty.is_expand : ''}`}
                alt='arrow'
                src={require('@/assets/imgs/icon-arrow-down@2x.png')}
              />
            }
            onClick={() => {
              formItem.expand = !formItem.expand;
              setUpdate(update + 1);
            }}
            type={formItem.labelType}
            expand={formItem.expand}
            options={options}
            defaultValue={formItem.defaultValue}
            onChange={(v, item) => {
              /// 默认选中不收起
              if (v !== formItem.defaultValue) {
                formItem.expand = false;
              }
              setUpdate(update + 1);
              updatePoint(item, itemList);
            }}
          />
        </Form.Item>
      </>
    );
  };

  return (
    <Form
      initialValues={initialValues}
      layout='horizontal'
      form={form}
      onValuesChange={handleValuesChange}
      className={sty.form}
    >
      <div className={sty.form_card}>
        {itemList.map((item, i) => (
          <PerItem
            formItem={item}
            index={i}
            key={`form_item_${item.labelCode}`}
          />
        ))}
      </div>
    </Form>
  );
}
