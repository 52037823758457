import React, { useEffect, useState } from 'react';
import sty from './index.module.scss';
import { CSelectorOptionsProps } from '@/utils/interface';
import { FormItem } from '..';

interface CSelectorProps {
  onChange?: (v: string, item: CSelectorOptionsProps) => void;
  value?: string;
  options: CSelectorOptionsProps[];
  defaultValue?: string;
  expand?: boolean
  type?: string;
  label: string;
  arrow?: React.ReactNode;
  onClick?: () => void
}

export default function Selector(props: CSelectorProps) {
  const { value: propsValue, options, onChange, defaultValue, expand = true, type, label, arrow, onClick } = props;

  const [value, setValue] = useState(propsValue ?? '');

  const handleChange = (item: CSelectorOptionsProps) => {
    setValue(item.value);
    onChange && onChange(item.value, item);
  };

  useEffect(() => {
    if (defaultValue && value === '') {
      setValue(defaultValue);
      const _item = options.find(item => item.value === defaultValue)
      _item && onChange && onChange(defaultValue, _item);
    }
  }, [defaultValue, value, options, onChange])


  return (
    <>
      <FormItem
        labelRender={type === 'H5' ? <h1 className={sty.h5_title}>{label}</h1> : null}
        footerRender={type === 'H5' ? (
          <ul className={sty.selector}>
            {options.map((item) => (
              <li
                key={item.value}
                className={value === item.value ? sty.is_active : ''}
                onClick={() => handleChange(item)}
              >
                {!!item.recommendText && <span>{item.recommendText}</span>}
                {item.label}
              </li>
            ))}
          </ul>
        ) : null}
        label={label} arrow={arrow} onClick={onClick}>
        {
          type != 'H5' && (
            <div className={`adm-input-element ${!value ? 'is_placeholder' : ''}`}>
              {value ? options.find(item => item.value == value)?.label : '请选择'}
            </div>
          )
        }
      </FormItem>
      {type != 'H5' && expand && (
        <ul className={sty.selector}>
          {options.map((item) => (
            <li
              key={item.value}
              className={value === item.value ? sty.is_active : ''}
              onClick={() => handleChange(item)}
            >
              {!!item.recommendText && <span>{item.recommendText}</span>}
              {item.label}
            </li>
          ))}
        </ul>
      )}
    </>
  );
}
