import React from 'react'
import sty from './index.module.scss'

interface ResultProps {
  icon: string;
  title: string;
  subtitle: string;
  company: {
    logo?: string;
    name: string
  }
}

export default function index(props: ResultProps) {
  const { icon, title, subtitle, company: { logo, name } } = props
  return (
    <div className={sty.result}>
      <img src={icon} alt="" />
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
      <div className={sty.company}>
        <p>您申请的产品/机构如下</p>
        <div className={sty.name}>
          {logo && <img src={logo} alt="" />}
          {name}
        </div>
      </div>
    </div>
  )
}
