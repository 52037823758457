import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { KeepAlive } from 'react-activation'
import Info from './routes/info'
import './App.scss'
const Home = lazy(() => import('./routes/home'));
const VerifyCode = lazy(() => import('./routes/verifyCode'))
const SubmitPage = lazy(() => import('./routes/submited'))
const Recommends = lazy(() => import('./routes/recommends'));
const IdVerify = lazy(() => import('./routes/idVerify'))
// const Info = lazy(() => import('./routes/info'))
const Demo = lazy(() => import('./routes/demo'))
const P1Detail = lazy(() => import('./routes/p1Detail'))
/// 申贷模板页
const ApplyLoanTemp = lazy(() => import('./routes/template'))
function App() {

  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/verifyCode' element={<VerifyCode />} />
      <Route path='/submited' element={<SubmitPage />} />
      <Route path='/recommends/:type' element={<Recommends />} />
      <Route path='/idVerify' element={<IdVerify />} />
      <Route path='/info' element={<KeepAlive name='/info'><Info /></KeepAlive>} />
      <Route path='/demo' element={<Demo />} />
      <Route path='/p1Detail/:productCode' element={<P1Detail />} />
      <Route path='/applyLoan' element={<ApplyLoanTemp />} />
    </Routes>
  );
}

export default App;
