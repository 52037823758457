import React, { useState } from 'react';
import sty from './index.module.scss';
import { CheckboxWidget, ProtocolModal } from '..';
import { ProductInfo, ProtocolsModel } from '@/utils/interface';
import { useNavigate } from 'react-router-dom';
import { Modal, Popup } from 'antd-mobile';
import request, { EHttpMethods } from '@/utils/request';
interface ProductItemProps {
  company: {
    logo: string;
    name: string;
  };
  /** 额度 */
  amount: string;
  /** 利率 */
  rate: string;
  /** 期限 */
  term: string | null | undefined;
  /** 产品等级：P1、P2、P3 */
  level: string;
  /** 选中 */
  showCheckbox?: boolean;
  onChecked?: (v: boolean) => {};
  /** 申请按钮 */
  showApplyBtn?: boolean;
  onApply?: (info: ProductItemProps) => {};
  users?: number;
  info: ProductInfo;
}

export default function useProductItem(props: ProductItemProps) {
  const navigate = useNavigate();

  const [showProtocol, setShowProtocol] = useState<ProtocolsModel | undefined>();

  const {
    showApplyBtn,
    onApply,
    showCheckbox,
    onChecked,
    level,
    term,
    rate,
    amount,
    company: { logo, name },
    users = 0,
    info,
  } = props;

  const handleApply = () => {
    switch (info.productType) {
      case 'P1':
        return navigate('/p1Detail/' + info.productCode);
      case 'P2':
        Modal.alert({
          title: '服务协议',
          showCloseButton: true,
          content: (
            <>
              <div>尊敬的用户，为您提供更多服务前，请先阅读并同意一下协议</div>
              {info.protocols.map((item, i) => (
                <p key={`modal_agree_${i}`}>
                  <a
                    href='#'
                    onClick={() => setShowProtocol(item)}
                  >
                    《{item.protocolName}》
                  </a>
                </p>
              ))}
            </>
          ),
          onConfirm: () => handleAssign('/smart-api/recommend/p2'),
          confirmText: '同意并继续',
        });
        return;
      case 'P3':
        handleAssign('/smart-api/recommend/p3');
        return;
    }
  };

  const handleAssign = (url: string) => {
    /// 签署
    request(url, {
      method: EHttpMethods.POST,
      data: {
        protocolCodeList: info.protocols
          ? info.protocols.map((item) => item.protocolCode)
          : undefined,
        productType: info.productType,
        productCode: info.productCode,
      },
    }).then((res: any) => {
      if (res.data && res.data.link) {
        window.location.href = res.data.link;
      }
    });
  };

  return (
    <div className={sty.product_item}>
      <div className={sty.head}>
        <img src={logo} alt={name} />
        {name}
      </div>
      <div className={sty.inner}>
        <div className={sty.item}>
          <h1 className={sty.amount}>{amount}</h1>
          <p>最高可贷（元）</p>
        </div>
        {level === 'P1' && (
          <>
            <div className={sty.item}>
              <h1>{info.yearInterestRate}</h1>
              <p>参考年化利率</p>
            </div>
            <div className={sty.item}>
              <h1>{info.servicePersonNum}</h1>
              <p>当日咨询人数</p>
            </div>
          </>
        )}
        {level !== 'P1' && (
          <div className={sty.info}>
            <p>
              参考借款期限：<span>{info.term}</span>
            </p>
            <p>
              参考年化利率：<span>{info.yearInterestRate}</span>
            </p>
          </div>
        )}
      </div>
      {showApplyBtn && (
        <div className={sty.float_center}>
          <button className={sty.btn} onClick={handleApply}>
            申请
          </button>
        </div>
      )}
      {showCheckbox && (
        <div className={sty.float_center}>
          <CheckboxWidget size='lg' onChecked={onChecked} />
        </div>
      )}
      <ProtocolModal
        visible={!!showProtocol}
        info={showProtocol}
        parentInfo={info}
        onClose={() => {
          setShowProtocol(undefined);
        }}
      />
    </div>
  );
}
