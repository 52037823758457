import React, { useEffect, useMemo, useRef, useState } from "react";
import sty from "./index.module.scss";
import { Form, Input, ProgressBar, Toast } from "antd-mobile";
import useFetchData from "@/hooks/useFetchData";
import request, { EHttpMethods, IResponseData } from "@/utils/request";
import { CityPicker, Loading, PrimaryBtn, ProtocolWidget } from "@/components";
import ExtraForm from "./extraForm";
import { LOCAL_FORM_DATA, LOCAL_MOBILE_KEY, LOCAL_NAME_KEY } from "@/utils/constant";
import { InfoItemsModel } from "@/utils/interface";
import { nanoid } from "nanoid";
import { useLocation, useNavigate } from "react-router-dom";
export default function useInfoPage(props: any) {
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [firstTimeQueryed, setFirstTimeQueryed] = useState(false);
  const [allDone, setAllDone] = useState(false);
  const [extraValues, setExtraValues] = useState<Record<string, string>>({});
  const [basicValues, setBasicValues] = useState({});
  const mainRef = useRef<HTMLDivElement>(null);
  const [infoItems, setInfoItems] = useState<InfoItemsModel>();
  const [updateIndex, setUpdateIndex] = useState(0);
  const protocolRef = useRef(null) as any;

  const progress = useMemo(() => {
    if (
      firstTimeQueryed &&
      (!infoItems?.productType || infoItems?.productType !== "P1")
    )
      return 100;
    if (!infoItems?.productType) return 30;
    if (infoItems?.itemList) {
      /// 过滤一级labelCode
      const level_1 = infoItems.itemList.map((item) => item.labelCode);
      const extraLength = Object.keys(extraValues).filter(
        (key) => level_1.includes(key) && !!extraValues[key]
      ).length;
      /// 初筛资料项已填写个数/初筛资料项 *0.7+30%
      return Math.round((extraLength / level_1.length) * 0.7 * 100 + 30);
    }
    return 30;
  }, [extraValues, infoItems, firstTimeQueryed]);

  /// 获取城市
  const { data, loading } = useFetchData("/smart-api/cf/city", {
    method: EHttpMethods.POST,
  });

  const checkName = (rule: any, value: any, cb: any) => {
    return new Promise((resolve, reject) => {
      if (!/^(?:[\u4e00-\u9fa5·]{2,16})$/g.test(value)) {
        reject("姓名格式不正确");
      } else {
        resolve("");
      }
    });
  };
  const checkAge = (rule: any, value: any, cb: any) => {
    return new Promise((resolve, reject) => {
      if (!/^\+?[1-9]\d*$/g.test(value) || +value > 100) {
        reject("年龄格式不正确");
      } else {
        resolve("");
      }
    });
  };

  const scrolToBottom = (extraValues: Record<string, string>) => {
    console.log("extraValues:::::", extraValues);
    setExtraValues(extraValues);
    if (
      !!Object.keys(extraValues).length &&
      Object.keys(extraValues).length ===
      Object.values(extraValues).filter(Boolean).length
    ) {
      setAllDone(true);
    }
    if (mainRef.current) {
      mainRef.current.scrollTo(0, mainRef.current.scrollHeight);
    }
  };

  useEffect(() => {
    window.document.title = '大福钱包'
    if (allDone && mainRef.current) {
      mainRef.current.scrollTo(0, mainRef.current.scrollHeight);
    }
    window.addEventListener('popstate', (state) => {
      setVisible(false);
    })
  }, [allDone]);

  /// 清空
  const clear = () => {
    window.localStorage.removeItem(LOCAL_NAME_KEY)
    setFirstTimeQueryed(false);
    setAllDone(false);
    setInfoItems(undefined);
    setUpdateIndex(updateIndex + 1);
  };

  /// 初筛
  const handleFormChange = () => {
    const values = form.getFieldsValue();
    if (!/^(?:[\u4e00-\u9fa5·]{2,16})$/g.test(values.customerName)) return clear();
    if (!/^\+?[1-9]\d*$/g.test(values.age) || +values.age > 100) return clear();
    if (!values.region || !values.region.length) return clear();
    clear();
    window.localStorage.setItem(LOCAL_NAME_KEY, values.customerName)
    Toast.show({
      icon: "loading",
      duration: 9999,
    });
    request<IResponseData>("/smart-api/pd/cc", {
      method: EHttpMethods.POST,
      data: {
        customerName: values.customerName,
        age: values.age,
        province: values.region[0],
        city: values.region[1],
        mobile: window.localStorage.getItem(LOCAL_MOBILE_KEY),
      },
    })
      .then((res: IResponseData) => {
        if (res && res.data) {
          setUpdateIndex(updateIndex + 1);
          setAllDone(false);
          setInfoItems(res.data);
          setFirstTimeQueryed(true);
        }
      })
      .finally(() => Toast.clear());
  };

  const handleOpen = (flag?: boolean) => {
    /// 有协议
    if (protocolRef.current) {
      if (!flag && !protocolRef.current.value) {
        return protocolRef.current.showModal(() => handleOpen(true));
      }
    }

    /// 存储数据
    const formData = { ...form.getFieldsValue(), ...extraValues };
    window.sessionStorage.setItem(LOCAL_FORM_DATA, JSON.stringify(formData));

    // window.location.href = `/idVerify?hash=${nanoid()}`;
    navigate(`/idVerify?hash=${nanoid()}`);
  };

  // 命中P1
  const _renderHitFooter = () => {
    return (
      <>
        {infoItems?.protocols && !!infoItems.protocols.length && (
          <ProtocolWidget
            ref={protocolRef}
            title="服务协议"
            subtitle="尊敬的用户，为您提供更多服务前，请先阅读并同意一下协议"
            agreements={infoItems.protocols}
          />
        )}
        <div className={sty.footer_inner}>
          <PrimaryBtn onClick={() => handleOpen()}>开启推荐</PrimaryBtn>
        </div>
      </>
    );
  };
  // 未命中
  const _renderNoneHitFooter = () => {
    return (
      <>
        {infoItems?.protocols && !!infoItems.protocols.length && (
          <ProtocolWidget
            ref={protocolRef}
            className={sty.p2_protocol}
            title="服务协议"
            subtitle="尊敬的用户，为您提供更多服务前，请先阅读并同意一下协议"
            agreements={infoItems.protocols}
          />
        )}
        <PrimaryBtn onClick={() => handleOpen()}>开启推荐</PrimaryBtn>
      </>
    );
  };

  return loading ? (
    <Loading />
  ) : (
    <div className="wrapper">
      <div className={sty.progress}>
        <h1>
          申请进度 <span>{progress}%</span>
        </h1>
        <ProgressBar
          percent={progress}
          style={{
            "--track-width": "4px",
            "--track-color": "#DFE6F1",
            "--fill-color": "#0D73FE",
          }}
        />
      </div>
      <div ref={mainRef} className={`main ${sty.main_wrapper}`}>
        <Form
          initialValues={basicValues}
          layout="horizontal"
          requiredMarkStyle="none"
          form={form}
          className={sty.form}
          validateTrigger="onBlur"
        /// onValuesChange={handleFormChange}
        >
          <div className={`${sty.form_card} ${sty.is_basic}`}>
            <div className={sty.form_header}>
              <img src={require("@/assets/imgs/icon-user@2x.png")} alt="" />
              <h1>请填写本人真实信息，审核通过率更高</h1>
              <p>信息仅用于身份验证，全程保护您的信息安全</p>
            </div>
            <Form.Item
              label="姓名"
              name="customerName"
              validateTrigger="onBlur"
              rules={[
                { validator: (rule, value, cb) => checkName(rule, value, cb) },
              ]}
            >
              <Input placeholder="请输入本人姓名" onBlur={handleFormChange} />
            </Form.Item>
            <Form.Item
              name="age"
              label="年龄"
              validateTrigger="onBlur"
              rules={[
                { validator: (rule, value, cb) => checkAge(rule, value, cb) },
              ]}
            >
              <Input
                placeholder="请输入年龄"
                type="number"
                onBlur={handleFormChange}
              />
            </Form.Item>
            <Form.Item
              name="region"
              label="常驻城市"
              onClick={() => setVisible(true)}
              rules={[{ required: true, message: "请选择城市" }]}
              validateTrigger="onBlur"
              arrow={
                <img
                  className={sty.arrow}
                  alt="arrow"
                  src={require("@/assets/imgs/icon-arrow-down@2x.png")}
                />
              }
            >
              <CityPicker
                onChange={handleFormChange}
                visible={visible}
                data={data.provinces}
                onClose={() => setVisible(false)}
              />
            </Form.Item>
          </div>
          <Form.Header />
        </Form>
        {firstTimeQueryed ? (
          infoItems?.productType === "P1" ? (
            <ExtraForm
              key={updateIndex}
              formInfo={infoItems}
              onChange={(allValues) => {
                setTimeout(() => scrolToBottom(allValues), 1);
              }}
            />
          ) : (
            _renderNoneHitFooter()
          )
        ) : null}
      </div>
      {infoItems?.productType === "P1" && allDone && (
        <div className="footer">{_renderHitFooter()}</div>
      )}
    </div>
  );
}
