import { stringify } from 'qs';
import { Toast } from 'antd-mobile';
import { LOCAL_MOBILE_KEY, REG_URL } from './constant';

const APIHOST = process.env.REACT_APP_API_DOMAIN; //'http://39.105.13.166:8081';

function filterObject(o: Record<string, string>, filter: Function) {
	const res: Record<string, string> = {};
	Object.keys(o).forEach((k) => {
		if (filter(o[k], k) || o[k] === '') {
			res[k] = o[k];
		}
	});
	const mobile = window.localStorage.getItem(LOCAL_MOBILE_KEY);
	const traceId = window.localStorage.getItem('traceId');
	!res.traceId && (res.traceId = traceId ?? '');
	!res.mobile && (res.mobile = mobile ?? '');

	return res;
}

export enum EHttpMethods {
	GET = 'GET',
	POST = 'POST',
	PUT = 'PUT',
	PATCH = 'PATCH',
	DELETE = 'DELETE',
}

type ICustomRequestError = {
	status: number;
	statusText: string;
	url: string;
};

/**
 * 错误处理
 * @param err
 * @param abortController
 */
function dealErrToast(
	err: Error & ICustomRequestError,
	abortController?: AbortController
) {
	switch (err.status) {
		case 408: {
			abortController && abortController.abort();
			typeof window !== 'undefined' && Toast.show(err.statusText);
			break;
		}
		default: {
			console.log(err);
			break;
		}
	}
}

/**
 * @description: 声明请求头header的类型
 */
interface IHeaderConfig {
	Accept?: string;
	'Content-Type': string;
	[propName: string]: any;
}

export interface IResponseData<T = any> {
	code: number;
	data: T;
	msg: string;
	traceId: string;
}

interface IAnyMap {
	[propName: string]: any;
}

export interface IRequestOptions {
	headers?: IHeaderConfig;
	signal?: AbortSignal;
	method?: EHttpMethods;
	query?: IAnyMap;
	params?: IAnyMap;
	data?: IAnyMap;
	body?: string;
	timeout?: number;
	credentials?: 'include' | 'same-origin';
	mode?: 'cors' | 'same-origin';
	cache?: 'no-cache' | 'default' | 'force-cache';
	isTxt?: boolean;
}

/**
 * Http request
 * @param url request URL
 * @param options request options
 */
interface IHttpInterface {
	request<T = IResponseData<any>>(
		url: string,
		options?: IRequestOptions
	): Promise<T>;
}

const CAN_SEND_METHOD = ['POST', 'PUT', 'PATCH', 'DELETE'];

class Http implements IHttpInterface {
	public async request<T>(
		url: string,
		options?: IRequestOptions,
		abortController?: AbortController
	): Promise<T> {
		const opts: IRequestOptions = Object.assign(
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					Accept: 'application/json',
				},
				credentials: 'include',
				timeout: 10000,
				mode: 'cors',
				cache: 'no-cache',
			},
			options
		);

		abortController && (opts.signal = abortController.signal);

		if (opts && opts.query) {
			url += `${url.includes('?') ? '&' : '?'}${stringify(
				filterObject(opts.query, Boolean)
			)}`;
		}

		const canSend =
			opts && opts.method && CAN_SEND_METHOD.includes(opts.method);

		if (canSend) {
			opts.body = JSON.stringify(filterObject(opts.data ?? {}, Boolean));
			// opts.body = JSON.stringify(opts.data);
			opts.headers &&
				Reflect.set(opts.headers, 'Content-Type', 'application/json');
		}

		console.log('Request Opts: ', opts);

		try {
			const res = await Promise.race([
				fetch(REG_URL.test(url) ? url : APIHOST + url, opts),
				new Promise<any>((_, reject) => {
					setTimeout(() => {
						return reject({
							status: 408,
							statusText: '请求超时，请稍后重试',
							url,
						});
					}, opts.timeout);
				}),
			]);
			if (opts.isTxt) {
				const _data = await res.text();
				return {
					code: 200,
					data: _data,
				} as any;
			}
			const result = await res.json();
			if (result.traceId) {
				window.localStorage.setItem('traceId', result.traceId);
			}
			if (result.code !== 200) {
				dealHttpErr(result);
			}
			return result;
		} catch (e: any) {
			dealErrToast(e, abortController);
			return e;
		}
	}
}

const dealHttpErr = (result: IResponseData<any>): any => {
	Toast.show(result.msg || '服务繁忙');
};

const { request } = new Http();

export { request as default };
