import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom'
import { AliveScope } from 'react-activation'
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'lib-flexible';
import './index.css'
import initRem from './utils/rem'
import { Loading } from '@/components'

initRem();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
/* 
  
    
  </React.StrictMode> */
root.render(
  <Suspense fallback={<Loading />}>
    <Router>
      <AliveScope>
        <App />
      </AliveScope>
    </Router>
  </Suspense>
);
reportWebVitals();
